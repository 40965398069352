import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";

export default function LawAppBar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies(['given_name', 'userid', 'picture', 'email', 'credential']);
    const navigate = useNavigate();
    return (
        <AppBar position="static">
            <Toolbar variant="dense">
                <IconButton
                    onClick={() => navigate('/')}
                    size="small"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography sx={{ flexGrow: 1, textAlign: "left" }}>
                    Counsel Assist AI
                </Typography>
                {(cookies.picture &&
                    (<div>
                        <Avatar src={cookies.picture}
                            onClick={handleMenu}
                            alt="profile" referrerPolicy="no-referrer" aria-controls="profile-menu"
                            sx={{ cursor: "pointer" }}
                        />
                        <Menu
                            id="profile-menu"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(Boolean(anchorEl))}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={onLogoutClicked}>Logout</MenuItem>
                        </Menu>
                    </div>)) ||
                    ('')}
            </Toolbar>
        </AppBar >
    );

    function handleMenu(event) {
        setAnchorEl(event.currentTarget);
    };

    function handleClose() {
        setAnchorEl(null);
    };

    function onLogoutClicked() {
        console.log('Removing cookies');
        removeCookie('given_name', { path: '/' });
        removeCookie('userid', { path: '/' });
        removeCookie('email', { path: '/' });
        removeCookie('picture', { path: '/' });
        removeCookie('credential', { path: '/' });
        console.log('After removing Cookie: ' + cookies.credential);

        setAnchorEl(null);
    }
}