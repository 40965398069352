import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, ButtonGroup, TextField, ToggleButton, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { useEffect } from 'react';
import { FileUploader } from "react-drag-drop-files";
import EditText from './EditText/EditText.js';

const caseTypes = [
    { label: 'Car Accident' },
    { label: 'Truck Accident' },
    { label: 'Motorcycle Accident' },
    { label: 'Bicycle Accident' },
    { label: 'Pedestrian accident' },
    { label: 'Boating Accident' },
    { label: 'Bus Accident' },
    { label: 'Auto Accident' },
    { label: 'Train Accident' },
    { label: 'Aircraft Accident' },
    { label: 'Aviation Accident' },
    { label: 'Recreational Vehicle Accident' },
    { label: 'Injuries from DUI' },
    { label: 'Traffic Accident' },
    { label: 'Motorcycle Insurance Claims' },
    { label: 'Insurance Claims' },
    { label: 'Slip and fall' },
    { label: 'Dog bite' },
    { label: 'Animal Attack' },
    { label: 'Swimming Pool Accident' },
    { label: 'Elevator Accident' },
    { label: 'Escalator Accident' },
    { label: 'Inadequate Security' },
    { label: 'Negligent Security' },
    { label: 'Fire and Burn Injury' },
    { label: 'Toxic Substances Exposure' },
    { label: 'Worker\'s Compensation' },
    { label: 'Construction Accident' },
    { label: 'Construction Injury' },
    { label: 'Industrial Accident' },
    { label: 'Medical malpractice' },
    { label: 'Birth Injuries' },
    { label: 'Nursing Home Abuse and Neglect' },
    { label: 'Pharmaceutical Injury' },
    { label: 'Pain and Suffering' },
    { label: 'Catastrophic Injury' },
    { label: 'Wrongful Death' },
    { label: 'Defective Product' },
    { label: 'Dangerous Drugs' },
    { label: 'Defective Medical Device' },
    { label: 'Sports and Recreational Accident' },
    { label: 'Product Liability' },
    { label: 'School and Playground Injury' },
    { label: 'Child Injury' },
    { label: 'Food Poisoning' },
    { label: 'Assault' },
    { label: 'Battery' },
    { label: 'Class Action Lawsuit' },
    { label: 'Brain Injury' },
    { label: 'Traumatic Brain Injury' },
    { label: 'Spinal Cord Injury' },
    { label: 'Severe Burn Injury' },
    { label: 'Railroad Accident' },
    { label: 'Maritime and Jones Act' },
    { label: 'Personal injury' },
];
caseTypes.sort((a, b) => a.label.localeCompare(b.label));

export default function CaseSummaryHeader({
    caseInfo,
    setCaseInfo,
    refFileNamesToFilePropertiesMap,
    setFileNamesToFilePropertiesMap,
    uploadingFileNamesSet,
    onFilesSelectedForUpload,
    setSnackBarMessage }) {

    const numTotalFiles = Object.keys(refFileNamesToFilePropertiesMap.current).length;
    var numSelectedFiles = Object.keys(refFileNamesToFilePropertiesMap.current).filter(filename => refFileNamesToFilePropertiesMap.current[filename]['selected']).length;
    // console.log('Initializing numSelectedFiles' + JSON.stringify(refFileNamesToFilePropertiesMap.current));

    let categoryToSourceFilenamesMap = {};
    for (let filename in caseInfo['documents']) {
        let category = caseInfo['documents'][filename].analysis?.overview?.length > 0 &&
            caseInfo['documents'][filename].analysis.overview[0].category?.length > 0 ?
            caseInfo['documents'][filename].analysis.overview[0].category :
            (caseInfo['documents'][filename].status?.includes('processing') ? 'Processing' : 'Unknown');

        if (!(category in categoryToSourceFilenamesMap)) {
            categoryToSourceFilenamesMap[category] = [];
        }
        categoryToSourceFilenamesMap[category].push(filename);
    }
    if (Object.keys(uploadingFileNamesSet).filter(filename => !(filename in caseInfo['documents'])).length > 0) {
        if (!('Processing' in categoryToSourceFilenamesMap)) {
            categoryToSourceFilenamesMap['Processing'] = [];
        }
        categoryToSourceFilenamesMap['Processing'].push(...Object.keys(uploadingFileNamesSet).filter(filename => !(filename in caseInfo['documents'])));
    }

    const [expanded, setExpanded] = React.useState(true);
    const onAccordianIconClicked = (prev) => { setExpanded(!expanded); }

    const gridItems = Object.keys(categoryToSourceFilenamesMap).map(category =>
        <Grid xs={1} key={category}>
            {renderFileTogglesForCategory(category, categoryToSourceFilenamesMap, refFileNamesToFilePropertiesMap.current)}
        </Grid>
    );
    useEffect(() => {
        console.log('CaseSummaryHeader: useEffect');
    });
    var caseHasDocuments = Object.keys(caseInfo['documents']).length > 0;

    return (
        <div>
            <Accordion expanded={expanded || !caseHasDocuments} >
                <AccordionSummary aria-controls="case-summary-header" id="panel1d-header"
                    // Make only icon clickable to expand/contract Accordian from https://stackoverflow.com/questions/69358781/material-ui-expand-accordion-by-clicking-the-icon-only
                    sx={{
                        pointerEvents: "none"
                    }}
                    expandIcon={<ExpandMoreIcon
                        sx={{
                            pointerEvents: "auto"
                        }}
                        onClick={onAccordianIconClicked}
                    />}
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, .03)',
                        flexDirection: "row-reverse",
                        cursor: "default",
                    }}
                >
                    <EditText showEditButton onSave={onFileNameChanged}
                        sx={{
                            pointerEvents: "auto"
                        }}
                        style={{ fontSize: "1.25rem", lineHeight: 1.6, fontWeight: 500, pointerEvents: "auto" }}
                        defaultValue={caseInfo ? caseInfo['name'] || caseInfo['caseId'] : ''} />
                    <Typography visibility={caseHasDocuments ? "visible" : "hidden"}
                        sx={{ color: 'text.secondary', 'marginTop': '10px!important' }}>
                        &nbsp;&nbsp; Showing information from {numSelectedFiles} selected documents (out of {numTotalFiles} uploaded)
                    </Typography>
                    <ButtonGroup
                        sx={{
                            visibility: caseHasDocuments ? "visible" : "hidden",
                            pointerEvents: "auto"
                        }}
                        orientation="vertical"
                        aria-label="vertical contained button group"
                        variant="text"
                        className="selectDocumentButtonGroup"
                    >
                        <Button
                            disabled={Object.keys(refFileNamesToFilePropertiesMap.current)
                                .filter(filename => refFileNamesToFilePropertiesMap.current[filename]['selected']).length === Object.keys(refFileNamesToFilePropertiesMap.current).length}
                            onClick={(event) => {
                                event.stopPropagation();
                                var newFileNamesToFilePropertiesMap = { ...refFileNamesToFilePropertiesMap.current };
                                Object.keys(newFileNamesToFilePropertiesMap).forEach(filename => {
                                    newFileNamesToFilePropertiesMap[filename]['selected'] = true;
                                })
                                setFileNamesToFilePropertiesMap(newFileNamesToFilePropertiesMap);
                            }}
                            key="one"
                            size="small">Select All</Button>
                        <Button disabled={Object.keys(refFileNamesToFilePropertiesMap.current)
                            .filter(filename => refFileNamesToFilePropertiesMap.current[filename]['selected']).length === 0}
                            onClick={(event) => {
                                event.stopPropagation();
                                var newFileNamesToFilePropertiesMap = { ...refFileNamesToFilePropertiesMap.current };
                                Object.keys(newFileNamesToFilePropertiesMap).forEach(filename => {
                                    newFileNamesToFilePropertiesMap[filename]['selected'] = false;
                                })
                                setFileNamesToFilePropertiesMap(newFileNamesToFilePropertiesMap);
                            }}
                            key="two"
                            size="small">Select None</Button>
                    </ButtonGroup>
                    <div style={{ marginLeft: "auto" }}
                        onClick={(e) => { e.stopPropagation() }}
                    >
                        <Autocomplete
                            style={{
                                visibility: (caseInfo['case_type'] && caseHasDocuments) ? "visible" : "hidden",
                                pointerEvents: "auto"
                            }}
                            value={caseInfo['case_type'] || null}
                            id="case_type"
                            // autoComplete={true} // not working
                            // autoHighlight={true} // not working
                            autoSelect={true}
                            onChange={onCaseTypeChanged}
                            options={caseTypes}
                            blurOnSelect={true}
                            onBlur={onCaseTypeBlur}
                            clearOnEscape={false}
                            selectOnFocus={true}
                            handleHomeEndKeys={true}
                            freeSolo={true}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} label="Case Type" />}
                        />
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                    <div display={caseInfo['case_type'] && caseHasDocuments ? "none" : "inherit"} style={{ margin: "70px 0 40px 0", display: caseInfo['case_type'] && caseHasDocuments ? "none" : "flex", flexDirection: "row", alignItems: "center", alignContent: "center", justifyContent: "center" }}>
                        <div style={{ marginRight: "10px" }}>
                            <Typography variant={"h6"} sx={{ fontWeight: caseInfo['case_type'] ? 300 : 700 }} >Enter Case Type</Typography>
                            <Typography variant="div" sx={{ fontWeight: 300, fontSize: "0.75em" }}>eg: Car Accident, Divorce, etc</Typography>
                        </div>
                        <Autocomplete
                            value={caseInfo['case_type'] || ''}
                            id="case_type"
                            // autoComplete={true} // not working
                            // autoHighlight={true} // Click on manually entered value and hitting enter causes first option to be selected
                            autoSelect={true}
                            onChange={onCaseTypeChanged}
                            options={caseTypes}
                            blurOnSelect={true}
                            onBlur={onCaseTypeBlur}
                            clearOnEscape={false}
                            selectOnFocus={true}
                            handleHomeEndKeys={true}
                            freeSolo={true}
                            sx={{ width: 400 }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                    <Grid container spacing={5} columns={4}>
                        {gridItems}
                    </Grid>
                    <div style={{ margin: caseHasDocuments ? "20px 0 0 0" : "100px 0 0 0" }}>
                        <Typography variant={"h6"} sx={{ fontWeight: caseInfo['case_type'] ? 700 : 400 }} >Upload Case Documents</Typography>
                        <Typography
                            sx={{ fontSize: "1.2em", fontWeight: "light" }}
                            // sx={{ fontWeight: 300, fontSize: "0.75em" }}
                            display={caseHasDocuments ? "none" : "inherit"}>
                            Generate summaries, extract key events, and run custom analysis.</Typography>
                    </div>
                    <FileUploader handleChange={onFilesSelectedForUpload} hoverTitle="Drop here please"
                        dropMessageStyle="{backgroundColor: 'red'}"
                        onDrop={console.log}
                        onDraggingStateChange={console.log}
                        name="file" types={["PDF", "TXT"]} //, "DOCX", "JPG", "PNG", "GIF"
                        multiple={true} >
                        <Box sx={{ border: '2px dashed #0a4c88', cursor: "pointer", padding: caseHasDocuments ? "" : "50px" }}>
                            <img style={{ height: "80px" }} src="/upload.png" alt="upload"></img>
                            <Box sx={{ display: "inline-flex", flexDirection: "column", verticalAlign: "top", marginTop: "10px" }}>
                                <Typography variant="h6" style={{ display: "inline" }} >Secure Upload</Typography>
                                <Typography variant="div" style={{ display: "inline" }}>Click or drop files to upload</Typography>
                            </Box>
                        </Box>
                    </FileUploader>
                </AccordionDetails>
            </Accordion>
        </div >
    );
    async function onFileNameChanged(changeObj) {
        var newFileName = changeObj.value;
        var newCaseInfo = {
            name: newFileName
        };
        let response;
        try {
            response = await fetch('/api/case/' + caseInfo['caseId'], {
                method: 'POST',
                credentials: 'same-origin',
                headers: new Headers({ 'content-type': 'application/json' }),
                body: JSON.stringify(newCaseInfo),
            });
        } catch (e) {
            console.log('File upload failed: ' + e);
        }
        if (response?.ok) {
            setSnackBarMessage('Case renamed to ' + newFileName);
        } else {
            setSnackBarMessage('ERROR: Failed to rename case. Please try again');
        }
        console.log('onFileNameChanged response' + response);
    }

    async function onCaseTypeChanged(event, newInputValue) {
        if (newInputValue === null) {
            console.log('Null case type. Returning');
            return;
        }
        console.log('onCaseTypeChanged newInputValue: ' + JSON.stringify(newInputValue));

        if (newInputValue === caseInfo['case_type']) {
            console.log('Same case type as before; returning:' + newInputValue);
            return;
        }
        var newCaseType = newInputValue ? newInputValue['label'] || newInputValue : '';

        var newCaseInfo = {
            ...caseInfo,
            case_type: newCaseType
        };
        setCaseInfo(newCaseInfo);

        let response;
        try {
            response = await fetch('/api/case/' + caseInfo['caseId'], {
                method: 'POST',
                credentials: 'same-origin',
                headers: new Headers({ 'content-type': 'application/json' }),
                body: JSON.stringify({ case_type: newCaseType }),
            });
        } catch (e) {
            console.log('File upload failed: ' + e);
        }
        if (response?.ok) {
            if (newCaseType) {
                setSnackBarMessage('Case type set to ' + newCaseType);
            } else {
                setSnackBarMessage('Case type removed');
            }
        } else {
            setSnackBarMessage('ERROR: Failed to set case type. Please try again');
        }
        console.log('onCaseTypeChanged response' + response);
    }

    /**
     * Handles case when user enters text and clicks outside. Default autocomplete behavior is to ignore 
     * this value and not trigger a onChange. To keep behavior consistency with EditText, we manually trigger
     * onCaseTypeChanged.
     * @param event blurEvent
     */
    async function onCaseTypeBlur(event) {
        // Add delay to handle case when a few letters are typed and enter is pressed to select 
        // autocompleted entry. In this case at the time of blur, the selected value is not
        // yet populated in the input.
        setTimeout(() => onCaseTypeChanged(event, event.target.value), 10);
    }

    function renderFileTogglesForCategory(category, categoryToSourceFilenamesMap, fileNamesToFilePropertiesMap) {
        // console.log('renderFileTogglesForCategory category' + category
        //     + '; categoryToSourceFilenamesMap: ' + JSON.stringify(categoryToSourceFilenamesMap)
        //     + ';\nrefFileNamesToFilePropertiesMap: ' + JSON.stringify(refFileNamesToFilePropertiesMap));

        const toggleButtons = categoryToSourceFilenamesMap[category].map(filename => {
            console.log('filename: ' + filename + '\n fileNamesToFilePropertiesMap[filename]: ' + JSON.stringify(fileNamesToFilePropertiesMap[filename]));
            return (<ToggleButton sx={{ margin: 1, width: "90%", fontSize: '0.75em' }}
                type='button'
                onChange={(event, value) => {
                    console.log('Click: value: ' + value);
                    var newFileNamesToFilePropertiesMap = { ...fileNamesToFilePropertiesMap };
                    newFileNamesToFilePropertiesMap[filename]['selected'] = !newFileNamesToFilePropertiesMap[filename]['selected'];
                    setFileNamesToFilePropertiesMap(newFileNamesToFilePropertiesMap);
                }}
                value={filename}
                selected={fileNamesToFilePropertiesMap[filename]['selected']}
                // aria-label="bold"
                key={filename}

                style={{ backgroundColor: fileNamesToFilePropertiesMap[filename]['selected'] ? "#E8F6FD" : '#EEE' }}

            // style={{ backgroundColor: fileNamesToFilePropertiesMap[filename]['selected'] ? fileNamesToFilePropertiesMap[filename]['color'] : '#EEE' }}
            >
                <img src="/loading.gif" alt="loading..." title="Processing...(~10 seconds per page)" style={{ "marginRight": "5px", height: "20px", display: fileNamesToFilePropertiesMap[filename]['status']?.includes('processing') ? "initial" : "none" }} />
                <img src="/error.png" alt="error" title="Error processing document. You can retry by uploading again. If the problem persists, please report it using the contact link." style={{ "marginRight": "5px", height: "20px", display: fileNamesToFilePropertiesMap[filename]['status']?.includes('error') ? "initial" : "none" }} />
                {filename.substring(0, 30)}{filename.length > 30 ? '...' : ''}
            </ToggleButton>)
        });
        return (
            <div>
                {/* <EventIcon eventCategory={category}></EventIcon> */}
                <Typography variant="h6">{category}</Typography>
                {toggleButtons}
            </div>);
    }
}