import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { createRef } from 'react';
import CopyToClipboardButton from './CopyToClipboardButton.js';

export default function DocumentsOverview({ caseInfo, fileNamesToFilePropertiesMap, displayedDocument, setDisplayedDocument, setSnackBarMessage }) {
    const tableRef = createRef();
    var documentNamesToDisplay = caseInfo !== null && 'documents' in caseInfo ?
        Object.keys(caseInfo['documents']).filter(filename => fileNamesToFilePropertiesMap[filename]['selected']) :
        [];
    var tableBodyRows = documentNamesToDisplay
        .sort((documentName1, documentName2) =>
            Date.parse(caseInfo['documents'][documentName1]?.analysis?.overview?.[0]?.date) -
            Date.parse(caseInfo['documents'][documentName2]?.analysis?.overview?.[0]?.date))
        .map(documentName => {
            let isProcessing = caseInfo['documents'][documentName]?.['status']?.includes('processing');
            let summary = caseInfo['documents'][documentName]?.analysis?.overview?.map(overview => overview.summary).join(' ');
            return (
                <TableRow
                    key={documentName} onClick={() => setDisplayedDocument({ 'name': documentName, 'page': 1 })}
                    className={documentName === displayedDocument?.['name'] ? "selected" : ""} >
                    <TableCell> {documentName} </TableCell>
                    <TableCell> {
                        caseInfo['documents'][documentName]?.analysis?.overview?.[0]?.date
                    }
                    </TableCell>
                    <TableCell> {
                        caseInfo['documents'][documentName].analysis?.document_type ||
                        (caseInfo['documents'][documentName].analysis?.overview?.length > 0 &&
                            caseInfo['documents'][documentName].analysis.overview[0].category?.length > 0 ?
                            (caseInfo['documents'][documentName].analysis.overview.filter(  // remove overviews with duplicate categories
                                (item, index) =>
                                    caseInfo['documents'][documentName].analysis.overview.map(item => item.category).indexOf(item.category) === index))
                                .map(item => item.category).join(', ') :
                            (caseInfo['documents'][documentName].status?.includes('processing') ? 'Processing' : 'Unknown'))
                    }
                    </TableCell>
                    <TableCell>
                        <img src="/loading.gif" alt="loading..."
                            title="Processing..."
                            style={{
                                marginRight: "5px",
                                height: "20px",
                                verticalAlign: "middle",
                                display: !summary && caseInfo['documents'][documentName]?.['status']?.includes('processing') ? "initial" : "none"
                            }} />
                        {
                            summary
                            ||
                            (isProcessing ?
                                (caseInfo['documents'][documentName]?.['status']?.includes('ocr') ?
                                    'OCR: Extracting text (~10 secs per page)' :
                                    'Analyzing...') :
                                '')
                        }
                    </TableCell>
                </TableRow>)
        })

    return (
        <div>
            <div style={{ display: "flex" }}>
                <CopyToClipboardButton nodeRef={tableRef} setSnackBarMessage={setSnackBarMessage}></CopyToClipboardButton>
            </div>
            <Table ref={tableRef} sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Document Name</TableCell>
                        <TableCell style={{ minWidth: "150px" }}>Date</TableCell>
                        <TableCell style={{ minWidth: "150px" }}>Category</TableCell>
                        <TableCell>Summary</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableBodyRows}
                </TableBody>
            </Table>
        </div>
    )
}
